.main {
    .azure-tower {
        .area-apartment {
            path {
                fill: rgba(255, 255, 255, 0.4) !important;
                stroke-width: 1 !important;

                &:hover {
                    fill: rgba(255, 255, 255, 0.75) !important;
                }
            }
        }
    }
}
