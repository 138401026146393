.main {
    .reel {
        .controls {
            max-width: 300px;
            width: 100%;
            height: 36px;

            background-color: #f4f8fd;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            padding: 6px;
            box-sizing: border-box;

            border-radius: 8px;

            pointer-events: all;

            .controls-button {
                width: 27px;
                height: 27px;

                background-color: #4e7393;
                border-radius: 8px;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                svg {
                    width: 18px;
                }
            }

            .controls-compass {
                overflow: hidden;

                width: 225px;
                height: 30px;

                cursor: e-resize;

                pointer-events: inherit;

                .controls-compass-inner {
                    height: 100%;

                    display: flex;
                    pointer-events: inherit;

                    .controls-compass-image {
                        height: 100%;

                        pointer-events: inherit;

                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -o-user-select: none;
                        user-select: none;

                        -webkit-user-drag: none;
                        user-select: none;
                        -moz-user-select: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                    }
                }
            }
        }
    }
}
