.modal {
    position: fixed;

    width: calc(100% - 40px);
    height: calc(100% - 40px);

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: #ffffff;
    border-radius: 12px;

    overflow: hidden;

    .modal-exit {
        position: absolute;
        z-index: 5;

        top: 15px;
        right: 15px;

        width: 50px;
        height: 50px;

        cursor: pointer;

        background-color: #000f2c;
        border-radius: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.3s;

        svg {
            width: 13px;
            height: 13px;

            rect {
                fill: #ffffff;
                transition: 0.3s;
            }
        }

        @media screen and (min-width: 800px) {
            &:hover {
                background-color: #ffffff;

                svg {
                    rect {
                        fill: #000000;
                    }
                }
            }
        }
    }
}
