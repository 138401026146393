@media screen and (max-width: 800px) {
    .main {
        .menu::before {
            border-radius: 0 0 0 0 !important ;
        }

        .menu {
            max-width: 100vw !important;
            padding: 10px 0 0 0 !important;

            .menu-title {
                display: none !important;
            }

            .menu-buttons {
                overflow-x: auto;
                flex-direction: row !important;

                .menu-button {
                    min-width: 100px !important;
                    margin-bottom: 10px;

                    .menu-button-area {
                        height: 32px !important;
                    }

                    &:first-of-type {
                        .menu-button-area {
                            border-radius: 0 !important;
                        }
                    }

                    &:last-of-type {
                        .menu-button-area {
                            border-radius: 0 !important;
                        }
                    }

                    &:first-of-type:last-of-type {
                        .menu-button-area {
                            border-radius: 0 !important;
                        }
                    }

                    .menu-button-elements {
                        border-radius: 12px 12px 0 0;

                        .menu-button-element {
                            &:first-of-type {
                                border-radius: 0 !important;
                            }

                            &:last-of-type {
                                border-radius: 0 !important;
                            }

                            &:first-of-type:last-of-type {
                                border-radius: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
