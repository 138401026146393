.main {
    .menu::before {
        content: "";
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: -1;
        top: 0;
        left: 0;

        background-color: rgba(0, 15, 44, 0.3);
        backdrop-filter: blur(10px);
        border-radius: 12px 12px 0 0;
    }

    .menu {
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        max-width: 200px;

        padding: 20px;
        box-sizing: border-box;

        user-select: none;

        .menu-title {
            margin-bottom: 10px;

            font-size: 20px;
            font-weight: 400;
            line-height: 130%;
            color: #ffffff;

            text-transform: uppercase;
        }

        .menu-buttons {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .menu-button {
                width: 100%;

                .menu-button-area {
                    width: 100%;
                    height: 32px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding: 0px 8px 0px 8px;
                    box-sizing: border-box;

                    font-size: 14px;
                    cursor: pointer;

                    border-radius: 1px;
                    cursor: pointer;

                    .menu-button-area-arrow {
                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                &:first-of-type {
                    .menu-button-area {
                        border-radius: 4px 4px 1px 1px;
                    }
                }

                &:last-of-type {
                    .menu-button-area {
                        border-radius: 1px 1px 4px 4px;
                    }
                }

                &:first-of-type:last-of-type {
                    .menu-button-area {
                        border-radius: 4px;
                    }
                }

                .menu-button-elements {
                    position: absolute;

                    width: 100%;
                    max-width: 160px;

                    background-color: rgba(0, 15, 44, 0.3);
                    backdrop-filter: blur(10px);
                    border-radius: 0 12px 12px 0;

                    padding: 20px;
                    box-sizing: border-box;

                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    .menu-button-element {
                        width: 100%;
                        height: 32px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        padding: 0px 8px 0px 8px;
                        box-sizing: border-box;

                        font-size: 14px;
                        cursor: pointer;

                        border-radius: 1px;
                        cursor: pointer;

                        &:first-of-type {
                            border-radius: 4px 4px 1px 1px;
                        }

                        &:last-of-type {
                            border-radius: 1px 1px 4px 4px;
                        }

                        &:first-of-type:last-of-type {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}
