@font-face {
    font-family: Helvetica;
    src: url(static/fonts/HelveticaNeueCyr-Medium.ttf);
    font-weight: 500;
}

.App {
    font-family: Helvetica;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #e1ddd5;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #4e7393;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(60, 90, 116);
}
