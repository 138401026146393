.main {
    .head-title {
        position: absolute;

        color: #ffffff;
        font-size: 28px;
        text-transform: uppercase;

        transform: translateX(-50%);

        text-align: center;
    }
}
