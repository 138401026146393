.main {
    .icon-button {
        position: absolute;

        max-width: 36px;
        width: 100%;
        height: 36px;

        background-color: #4e7393;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        .icon-button-image {
            width: 18px;
            height: 18px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .icon-button-label {
            position: absolute;
            top: -10px;
            right: -10px;

            width: 20px;
            height: 20px;

            background-color: #000000;
            border-radius: 50%;

            color: #ffffff;
            font-size: 10px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
