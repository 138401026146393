@media screen and (max-width: 800px) {
    .main {
        .reel {
            .reel-menu {
                left: 50% !important;
                transform: translateX(-50%);
                bottom: 65px !important;

                width: calc(100% - 24px) !important;

                .controls {
                    width: calc(100% - 45px) !important;
                }
            }
        }
    }
}
