.main {
    .gradient {
        position: absolute;

        width: 100%;
        background: linear-gradient(
            180deg,
            rgba(244, 248, 253, 0.8) 0,
            rgba(244, 248, 253, 0) 100%
        );
        pointer-events: none;
    }
}
