.loading {
    position: absolute;

    width: 100%;
    height: 100dvh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #4e7393;

    .loading-image {
        width: 35vmin;
    }
}
