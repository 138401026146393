.main {
    .svg-spots {
        svg {
            position: absolute;

            width: auto;
            height: 100%;

            @media (min-aspect-ratio: 1.777777777777778) {
                width: 100%;
                height: auto;
            }

            pointer-events: none;

            .svg-spot {
                -webkit-tap-highlight-color: transparent;

                cursor: pointer;
                pointer-events: all;

                .svg-spot-circle {
                    fill: #4e7393;
                    box-sizing: border-box;
                    stroke: #ffffff;
                    stroke-width: 4;
                }

                .svg-label {
                    opacity: 0;
                    transition: 0.2s;
                }

                @media screen and (min-width: 800px) {
                    &:hover {
                        .svg-label {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
