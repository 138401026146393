.main {
    svg {
        .svg-label {
            pointer-events: none;

            .svg-label-background {
                fill: rgba(0, 15, 44, 1);
            }

            .svg-label-text {
                fill: #ffffff;
                font-weight: 400;
            }
        }
    }
}
