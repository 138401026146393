.reel {
    width: 100%;
    height: 100%;

    .reel-frame {
        width: 100%;
        height: 100%;

        .reel-menu {
            position: absolute;
            right: 12px;
            bottom: 17px;

            width: 100%;
            max-width: 345px;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
