.infobox {
    .apartment-info {
        width: 240px;

        display: flex;
        flex-direction: column;
        align-items: center;

        pointer-events: all;

        user-select: none;

        .apartment-info-image {
            width: 100%;
            height: 200px;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 12px;
            box-sizing: border-box;

            img {
                width: 100%;
            }
        }

        .apartment-info-data {
            width: 100%;

            padding: 24px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;

            gap: 12px;

            background-color: #f4f8fd;

            .apartment-info-title {
                font-size: 20px;
                font-weight: 500;
                color: #4c4c4c;
            }

            .apartment-info-specs {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .apartment-info-spec {
                    position: relative;

                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    font-size: 14px;
                    font-weight: 500;
                    color: #4c4c4c;

                    .apartment-info-spec-name {
                        text-transform: capitalize;
                    }

                    .apartment-info-spec-line {
                        display: flex;
                        flex: 1 1 5%;
                        margin: 0 0.5ch 0.4ch;
                        border-bottom: 2px solid #4e73938f;
                    }

                    .apartment-info-spec-value-highlighted {
                        color: #eff0f2;
                        background-color: #000f2c;
                        padding: 2px 8px;
                        border-radius: 4px;
                    }
                }
            }

            .apartment-info-button {
                width: 100%;
                height: 36px;

                background-color: #000f2c;
                color: #f4f8fd;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 8px;

                font-size: 14px;
                font-weight: 500;
                line-height: 130%;

                cursor: pointer;
            }
        }
    }
}
